<template>
    <AppPanel :location="[title]" subtitle="Preencha os campos abaixo" :hasBackButton="true" backPath="/procedure">
        <template #content>
            <div class="p-fluid painel painel-2">
                <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                    <AppInfoManual nomeTelaDoManual="riscos-procedimentos-form" />
                    <h3 class="ml-1">{{ title }}</h3>
                </div>
                <TabView :scrollable="true" ref="tabview" :activeIndex="active" @tab-click="onTabClick">
                    <TabPanel :header="`${form?.risco?.nome} x ${form?.procedimento?.name}`" :disabled="false" :key="0">
                        <SolicitaTipoExameOcupGrid
                            :queryParams="queryParamsTipoExame"
                            :service="serviceTipoExame"
                            :parentName="parentNameTipoExame"
                            :labelBotaoAdicionarItem="labelBotaoAdicionarItemTipoConsulta"
                        />

                        <Divider />
                        <h3>Periodicidade</h3>

                        <Periodicidades origem="risco-procedimento" :lastPage="false" personalBackPath="/procedure" :risco="form?.risco" />
                    </TabPanel>
                </TabView>
            </div>
        </template>
    </AppPanel>
</template>

<script>
import { getCurrentCompany } from '@/services/store';
import Periodicidades from '../../components/periodicidade/PeriodicidadeCadastro.vue';
import SolicitaTipoExameOcupGrid from '../../components/solicita-tipo-exame-ocup/SolicitaTipoExameOcupGrid.vue';
import NestedService from '../../services/NestedService';
import BaseService from '../../services/BaseService';
import AppInfoManual from '../../components/AppInfoManual.vue';

export default {
    components: {
        Periodicidades,
        SolicitaTipoExameOcupGrid,
        AppInfoManual
    },
    created() {
        this.service = new BaseService('/risco-procedimento');
        this.serviceTipoExame = new NestedService('/solicita-tipo-exame-ocup');
    },
    mounted() {
        this.load();
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar Risco procedimento' : 'Adicionar Risco procedimento';
        },
        queryParamsTipoExame() {
            return {
                origem: 'risco-procedimento',
                idOrigem: this.$route.params.id
            };
        }
    },
    data() {
        return {
            active: 0,
            form: {},
            serviceTipoExame: null,
            parentNameTipoExame: 'risco-procedimento',
            labelBotaoAdicionarItemTipoConsulta: 'Adicionar tipo de consulta'
        };
    },
    methods: {
        async load() {
            const { data } = await this.service.findById(this.$route.params.id);
            this.form = data;
        },
        getCurrentCompany() {
            return getCurrentCompany();
        },
        onTabClick(event) {
            this.active = event.index;
        }
    }
};
</script>